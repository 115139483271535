// nationalities.js
const nationalities = [
    "Afghane", "Albanaise", "Algérienne", "Allemande", "Américaine", 
    "Andorrane", "Angolaise", "Antiguaise-et-Barbudienne", "Argentine", 
    "Arménienne", "Australienne", "Autrichienne", "Azerbaïdjanaise", 
    "Bahamienne", "Bahreïnienne", "Bangladaise", "Barbadienne", "Belge", 
    "Bélizienne", "Béninoise", "Bhoutanaise", "Biélorusse", "Birmane", 
    "Bolivienne", "Bosnienne", "Botswanaise", "Brésilienne", "Britannique", 
    "Brunéienne", "Bulgare", "Burkinabè", "Burundaise", "Cambodgienne", 
    "Camerounaise", "Canadienne", "Cap-verdienne", "Centrafricaine", 
    "Chilienne", "Chinoise", "Chypriote", "Colombienne", "Comorienne", 
    "Congolaise", "Costaricienne", "Croate", "Cubaine", "Danoise", 
    "Djiboutienne", "Dominicaine", "Dominiquaise", "Égyptienne", "Émirienne", 
    "Équato-guinéenne", "Équatorienne", "Érythréenne", "Espagnole", 
    "Estonienne", "Éthiopienne", "Fidjienne", "Finlandaise", "Française", 
    "Gabonaise", "Gambienne", "Géorgienne", "Ghanéenne", "Grecque", 
    "Grenadienne", "Guatémaltèque", "Guinéenne", "Guinéenne-Bissau", 
    "Guyanienne", "Haïtienne", "Hondurienne", "Hongroise", "Indienne", 
    "Indonésienne", "Irakienne", "Iranienne", "Irlandaise", "Islandaise", 
    "Israélienne", "Italienne", "Ivoirienne", "Jamaïcaine", "Japonaise", 
    "Jordanienne", "Kazakhstanaise", "Kényane", "Kirghize", "Kiribatienne", 
    "Kittitienne", "Kosovare", "Koweïtienne", "Laotienne", "Lesothane", 
    "Lettone", "Libanaise", "Libérienne", "Libyenne", "Liechtensteinoise", 
    "Lituanienne", "Luxembourgeoise", "Macédonienne", "Malaisienne", 
    "Malawienne", "Maldivienne", "Malienne", "Maltaise", "Marocaine", 
    "Marshallaise", "Mauricienne", "Mauritanienne", "Mexicaine", 
    "Micronésienne", "Moldave", "Monégasque", "Mongole", "Monténégrine", 
    "Mozambicaine", "Namibienne", "Nauruan", "Népalaise", "Nicaraguayenne", 
    "Nigériane", "Nigérienne", "Nord-Coréenne", "Norvégienne", "Omanaise", 
    "Ougandaise", "Ouzbèke", "Pakistanaise", "Palauane", "Palestinienne", 
    "Panaméenne", "Papouane-néo-guinéenne", "Paraguayenne", "Péruvienne", 
    "Philippine", "Polonaise", "Portugaise", "Qatari", "Roumaine", "Russe", 
    "Rwandaise", "Saint-Lucienne", "Saint-Marinaise", 
    "Saint-Vincentaise-et-Grenadine", "Salomonaise", "Samoane", 
    "Santoméenne", "Saoudienne", "Sénégalaise", "Serbe", "Seychelloise", 
    "Sierraléonaise", "Singapourienne", "Slovaque", "Slovène", "Somalienne", 
    "Soudanaise", "Sri-Lankaise", "Sud-Africaine", "Sud-Coréenne", 
    "Sud-Soudanaise", "Suédoise", "Suisse", "Surinamaise", "Swazie", 
    "Syrienne", "Tadjike", "Tanzanienne", "Tchadienne", "Tchèque", 
    "Thaïlandaise", "Timoraise", "Togolaise", "Tonguienne", "Trinidadienne", 
    "Tunisienne", "Turkmène", "Turque", "Tuvaluane", "Ukrainienne", 
    "Uruguayenne", "Vanuatuane", "Vénézuélienne", "Vietnamienne", "Yéménite", 
    "Zambienne", "Zimbabwéenne"
  ];
  
  export default nationalities;
  